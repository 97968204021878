import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { ToastContainer, toast } from 'react-toastify';
import api from 'src/services/api';
import keys from 'src/services/keys';
import getApiErrors from 'src/utils/getApiErrors';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import logo from 'src/assets/img/logo-pv.png';
import arte from 'src/assets/img/arte-login.png';
import sso from 'src/assets/img/logo-sso.png';
import lock from 'src/assets/img/ico-lock.png';
import user from 'src/assets/img/ico-user.png';
import InputLogin from 'src/components/InputLogin';
import BockInput from 'src/components/BockInput';
import ButtonLogin from 'src/components/ButtonLogin';
import ButtonBackLogin from 'src/components/ButtonBackLogin';
import {
  Container,
  Logo,
  Form,
  LeftBox,
  RightBox,
  LogoRight,
  TextEntre,
  TextFooter,
  ImageSSO,
  ImageLock,
  ImageUser,
  TextORSSO,
  ReCaptcha,
  HRORSSOs,
  MenuItemLink,
  TextTitle,
  Title,
} from './styles';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [showRecaptcha, setShowRecaptcha] = useState(
    keys.REACT_APP_RECAPTCHA_ENABLED === 'true'
  );

  const [recaptcha_privateke, setRecaptcha_privateke] = useState<any>(
    '5948086e-e295-43a2-8b3d-189b7723db99'
  );

  const funcErrorEmail = (stateError) => setErrorEmail(stateError);

  const changeRecaptha = (value) => setRecaptchaValue(value);

  const changeEmail = (email) => {
    setEmail(email);
    funcErrorEmail(false);
  };

  const IsEmail = (email) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(email);
  };

  const backLogin = () => {
    history.push('/');
  };

  const forgotRequest = async () => {
    if (!email) {
      toast.error('E-mail não pode ser vazio!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return funcErrorEmail(true);
    }

    if (!IsEmail(email)) {
      toast.error('E-mail digitado não é valido!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return funcErrorEmail(true);
    }

    if (showRecaptcha && !recaptchaValue) {
      toast.error('Validação do hCaptcha é obrigatória!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const loading = true;
      dispatch(AuthActions.authLoading(loading));
      const data = {
        email: email,
        'h-captcha-response': recaptchaValue,
      };
      try {
        const d = await api.user.post('/api/v1/users/password/remember', data);
        console.log(d);
        history.push('/esqueci-minha-senha/info', data);
      } catch (error) {
        const loading = false;
        dispatch(AuthActions.authLoading(loading));
        console.log(error?.response?.status);
        if (error?.response?.status === 409) {
          toast.error(
            'Empresa com SSO ativado, por favor entre em contato com setor técnico para troca de senha.',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          funcErrorEmail(true);
        }
        if (error?.response?.status === 429) {
          toast.error(
            'Limite máximo de solicitações de recuperação de senha alcançado. Favor, verificar sua caixa de email.',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          funcErrorEmail(true);
        }
        if (error?.response?.status === 404) {
          history.push('/esqueci-minha-senha/info', data);
        }
      }
    }
  };

  return (
    <Container>
      <Form>
        <LeftBox>
          <TextEntre>Bem-vindo à Revolução</TextEntre>

          <Logo>
            <img src={logo} alt="Plataforma Verde" />
          </Logo>

          <HRORSSOs>
            <TextORSSO>REDEFINIR SENHA DE ACESSO</TextORSSO>
          </HRORSSOs>
          <Title>Precisamos verificar sua identidade!</Title>
          <TextTitle>
            Informe o e-mail utilizado na plataforma para enviarmos o link de
            redefinição de senha.
          </TextTitle>

          <BockInput error={errorEmail}>
            <ImageLock>
              <img src={user} alt="Plataforma Verde" />
            </ImageLock>
            <InputLogin
              type="email"
              placeholder="Nome de usuário ou e-mail"
              onChange={(e) => changeEmail(e.target.value)}
            />
          </BockInput>
          {showRecaptcha && (
            <ReCaptcha>
              <HCaptcha
                sitekey={recaptcha_privateke}
                onVerify={(token) => changeRecaptha(token)}
              />
            </ReCaptcha>
          )}
          <ButtonLogin onClick={() => forgotRequest()} type="button">
            ENVIAR LINK
          </ButtonLogin>

          <HRORSSOs>
            <TextORSSO>OU</TextORSSO>
          </HRORSSOs>

          <MenuItemLink onClick={() => backLogin()}>
            <ButtonBackLogin type="button">
              acessar usando login / senha
            </ButtonBackLogin>
          </MenuItemLink>

          <TextFooter>problemas de acesso? entre em nosso chat</TextFooter>
        </LeftBox>

        <RightBox>
          <LogoRight>
            <img src={arte} alt="Plataforma Verde" />
          </LogoRight>
        </RightBox>
      </Form>
    </Container>
  );
};

export default ForgotPassword;
